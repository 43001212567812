import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { OnePagerConclusionsComponent } from '../shared/one-pager/one-pager-conclusions/one-pager-conclusions.component';
import { OnePagerContextComponent } from '../shared/one-pager/one-pager-context/one-pager-context.component';
import { OnePagerImagesComponent } from '../shared/one-pager/one-pager-images/one-pager-images.component';
import { AuthGuardService } from '../shared/services/authenticationGuard.service';
import { AnalyseComponent } from './detail/edit-campaign/analyse/analyse.component';
import { ReportsComponent } from './detail/edit-campaign/analyse/reports/reports.component';
import { CampaignDisplayModeComponent } from './detail/edit-campaign/display-mode/display-mode.component';
import { CampaignFormulaComponent } from './detail/edit-campaign/formula/campaign-formula.component';
import { RoutinesComponent } from './detail/edit-campaign/formula/routines/routines.component';
import { GeneralInformationComponent } from './detail/edit-campaign/general-information/general-information.component';
import { OnePagerHeadComponent } from './detail/edit-campaign/one-pager/one-pager-head/one-pager-head.component';
import { OnePagerPreviewComponent } from './detail/edit-campaign/one-pager/one-pager-preview/one-pager-preview.component';
import { OnePagerTargetProtocolComponent } from './detail/edit-campaign/one-pager/one-pager-target-protocol/one-pager-target-protocol.component';
import { OnePagerComponent } from './detail/edit-campaign/one-pager/one-pager.component';
import { OnePagerResolver } from './detail/edit-campaign/one-pager/one-pager.resolver';
import { CampaignParameterComponent } from './detail/edit-campaign/parameters/campaign-parameter.component';
import { PhotosComponent } from './detail/edit-campaign/photos/gestion-photos/photos.component';
import { TimepointPhotosComponent } from './detail/edit-campaign/photos/gestion-photos/timepoints/timepointPhotos.component';
import { VolunteerPhotosComponent } from './detail/edit-campaign/photos/gestion-photos/volunteers/volunteerPhotos.component';
import { ProtocolComponent } from './detail/edit-campaign/protocol/protocol.component';
import { CampaignPublicationComponent } from './detail/edit-campaign/publication/campaign-publication.component';
import { EvaluationDetailComponent } from './detail/edit-campaign/raw-data/detail/evaluation-detail.component';
import { EvaluationsComponent } from './detail/edit-campaign/raw-data/list/evaluations.component';
import { TargetComponent } from './detail/edit-campaign/targets/target.component';
import { UsersComponent } from './detail/edit-campaign/users/users.component';
import { VisitsComponent } from './detail/edit-campaign/visits/visits.component';
import { CampaignWorkflowsComponent } from './detail/edit-campaign/workflows/campaign-workflows.component';
import { PowerBIPageComponent } from './detail/edit-campaign/workflows/power-bi/page/power-bi-page.component';
import { CampaignRootComponent } from './detail/root/campaign-root.component';
import { CampaignsComponent } from './list/campaigns.component';
import { NoWorkspacesComponent } from './no-workspaces/no-workspaces.component';

const appRoutes: Routes = [
  {
    path: 'noWorkspaces',
    component: NoWorkspacesComponent,
    canActivate: [AuthGuardService],
    data: {
      role: 'DNA_SCHEDULE'
    }
  },
  {
    path: 'campaigns',
    component: CampaignsComponent,
    canActivate: [AuthGuardService],
    data: {
      role: 'DNA_SCHEDULE'
    }
  },
  {
    path: 'campaigns/:idCampaign/edit/formulas/routines',
    component: RoutinesComponent,
    canDeactivate: [AuthGuardService],
    data: {
      role: 'DNA_SCHEDULE'
    }
  },
  {
    path: 'campaigns/:idCampaign/edit/analyse/reports',
    component: ReportsComponent,
    canActivate: [AuthGuardService],
    data: {
      role: 'DNA_SCHEDULE'
    }
  },
  {
    path: 'campaigns/:idCampaign/edit/analyse/reports/:isNewReport',
    component: ReportsComponent,
    canActivate: [AuthGuardService],
    data: {
      role: 'DNA_SCHEDULE'
    }
  },
  {
    path: 'campaigns/:idCampaign/edit/photos/volunteer/:volunteerId',
    component: VolunteerPhotosComponent,
    data: {
      role: 'DNA_DEV'
    }
  },
  {
    path: 'campaigns/:idCampaign/edit/photos/timepoint/:timepointId',
    component: TimepointPhotosComponent,
    data: {
      role: 'DNA_DEV'
    }
  },
  {
    path: 'campaigns/:idCampaign/edit',
    component: CampaignRootComponent,
    canActivate: [AuthGuardService],
    data: {
      role: 'DNA_SCHEDULE'
    },
    children: [
      {
        path: '',
        redirectTo: 'generalInformation',
        pathMatch: 'full'
      },
      {
        path: 'generalInformation',
        component: GeneralInformationComponent,
        canDeactivate: [AuthGuardService]
      },
      {
        path: 'protocol',
        component: ProtocolComponent,
        canDeactivate: [AuthGuardService]
      },
      {
        path: 'target',
        component: TargetComponent,
        canDeactivate: [AuthGuardService]
      },
      {
        path: 'displayMode',
        component: CampaignDisplayModeComponent,
        canDeactivate: [AuthGuardService]
      },
      {
        path: 'photos',
        component: PhotosComponent,
      },
      
      {
        path: 'workflows',
        component: CampaignWorkflowsComponent,
        canDeactivate: [AuthGuardService]
      },
      {
        path: 'visits',
        component: VisitsComponent,
        canDeactivate: [AuthGuardService]
      },
      {
        path: 'formulas',
        component: CampaignFormulaComponent,
        canDeactivate: [AuthGuardService]
      },
      {
        path: 'users',
        component: UsersComponent,
        canDeactivate: [AuthGuardService]
      },
      {
        path: 'parameters',
        component: CampaignParameterComponent,
        canDeactivate: [AuthGuardService]
      },
      {
        path: 'publish',
        component: CampaignPublicationComponent,
        canDeactivate: [AuthGuardService]
      },
      {
        path: 'rawdata',
        component: EvaluationsComponent
      },
      {
        path: 'rawdata/:idEvaluation/detail',
        component: EvaluationDetailComponent
      },
      {
        path: 'analyse',
        component: AnalyseComponent
      },
      {
        path: 'analyse/:idWorkflow/powerBIDescription',
        component: PowerBIPageComponent,
        canDeactivate: [AuthGuardService]
      },
      {
        path: 'onepager',
        component: OnePagerComponent,
        resolve: {
          onePager: OnePagerResolver
        },
        children: [
          {
            path: '',
            redirectTo: 'head',
            pathMatch: 'full'
          },
          {
            path: 'head',
            component: OnePagerHeadComponent,
            canDeactivate: [AuthGuardService]
          },
          {
            path: 'context',
            component: OnePagerContextComponent,
            canDeactivate: [AuthGuardService]
          },
          {
            path: 'target',
            component: OnePagerTargetProtocolComponent,
            canDeactivate: [AuthGuardService]
          },
          {
            path: 'image',
            component: OnePagerImagesComponent,
            canDeactivate: [AuthGuardService]
          },
          {
            path: 'conclusion',
            component: OnePagerConclusionsComponent,
            canDeactivate: [AuthGuardService]
          },
          {
            path: 'preview',
            component: OnePagerPreviewComponent,
            canDeactivate: [AuthGuardService]
          }
        ]
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(appRoutes),
    CommonModule
  ]
})
export class CampaignRoutingModule { }

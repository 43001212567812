import {
  throwError as observableThrowError,
  Observable,
  throwError,
  of,
  concat,
  Subject, Subscription
} from 'rxjs';

import { catchError, finalize, mergeMap, takeUntil, tap } from 'rxjs/operators';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import * as _ from 'lodash';
import * as FileSaver from 'file-saver';
import {
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

import {
  Campaign,
  CampaignPublication,
  CampaignStates,
  Workflow,
  CampaignPublicationPut,
  User,
  AttributeData, OnePager, States,
} from '../../../../types';
import {
  CampaignService
} from '../../../campaigns.service';
import { environment } from '../../../../../environments/environment';
import {
  ErrorManagerService
} from '../../../../shared/services/errorManager.service';
import {
  ImportExcelModalComponent
} from './import-excel/import-excel-modal.component';
import { ModalRandomisationComponent } from './randomisation/modal-randomisation.component';
import {
  UserService
} from '../../../../shared/services/user.service';
import {
  UtilService
} from '../../../../shared/services/util.service';
import {ErrorsImportExcelModalComponent} from './import-excel/errors-import-excel-modal.component';
import {ConfirmPublishModalComponent} from './confirm-publish-modal/confirm-publish-modal.component';
import {ActiviewDataComponent} from '../../../../shared/modals/actiview-data/actiview-data.component';
import {AddCharacterizationComponent} from '../workflows/power-bi/add-caracterization/add-characterization.component';
import {AbandonCaseEnum} from '../../../../enums/campaign/abandonCase.enum';
import { ConfortModalComponent } from './confort/confort-modal.component';

@Component({
  selector: 'dna-campaign-publication',
  templateUrl: './campaign-publication.component.html',
  styleUrls: ['./campaign-publication.component.less']
})
export class CampaignPublicationComponent implements OnInit, OnDestroy {

  campaignPublication: CampaignPublication;
  campaignStates: typeof CampaignStates = CampaignStates;
  displayRandomisation: boolean;
  linkAuthorized = false;
  showSpinner = false;
  url: SafeResourceUrl;
  error = false;
  hasEvaluations = false;
  idCampaign: string;
  user: User;
  isConfort: boolean;
  private _cancelCampaignObservable: Subscription;


  modalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };

  modalSmallOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private campaignService: CampaignService,
    private errorManager: ErrorManagerService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private utilService: UtilService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    if (this._cancelCampaignObservable) {
      this._cancelCampaignObservable.unsubscribe();
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  canDeactivate(): Observable<boolean> | boolean {
    return true;
  }

  private init() {
    this.error = false;
    this.idCampaign = this.route.parent.snapshot.paramMap.get('idCampaign');
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.mobile_url() + '/#/campaigns/' + this.idCampaign);
    this.showSpinner = true;
    this.user = this.userService.getUser();
    this.isConfort = this.userService.isConfort()

    this.campaignService.getCampaignPublication(this.idCampaign).pipe(
      tap(cp => this.campaignPublication = cp),
      tap(cp => this.linkAuthorized = this.campaignService.getUserAuthorizedforCampaign(cp.users.accountables, this.userService.getUser()) && new Date().getTime() > this.campaignPublication.startDate),
      tap(() => this.displayRandomisation = this.utilService.isNotEditable(this.campaignPublication.state)),
      tap(() => this.hasEvaluations = !_.isEmpty(_.get(this.campaignPublication, 'evaluations', []))),
      tap(() => this.campaignService.updateBreadCrumb(this.campaignPublication.name)),
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
      takeUntil(this.destroy$),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }


  export(campaignPublication: CampaignPublication) {
    this.showSpinner = true;
    if (campaignPublication.allSidesNotDefined) {
      this.errorManager.displayMessage('ERROR_IMPORT_SIDE_NOT_DEFINED', 'warning');
      this.showSpinner = false;
    } else {
      this.campaignService.exportEvaluationToExcel(campaignPublication.id).pipe(
        finalize(() => this.showSpinner = false)
      )
        .subscribe(
          blob => FileSaver.saveAs(blob, `${campaignPublication.name}_${new Date().getTime()}.xlsx`),
          err => this.errorManager.catchError(err)
        );
    }
  }

  import() {
    const modal = this.modalService.open(ImportExcelModalComponent, this.modalOption);
    modal.componentInstance.campaignId = this.campaignPublication.id;
    modal.result.then((errors) => {
      if (!_.isEmpty(errors)) {
        const errorsModal = this.modalService.open(ErrorsImportExcelModalComponent, this.modalOption);
        errorsModal.componentInstance.title = 'ERRORS_IMPORT_EXCEL_FILE';
        errorsModal.componentInstance.errorsTab = errors;
      }
    });
  }

  onToxAgreementChange() {
    if (this.campaignPublication.state == CampaignStates.Draft || this.campaignPublication.state == CampaignStates.Suspended) {
      this.showSpinner = true;
      return this.campaignService.putCampaignPublicationToxAgreement(this.campaignPublication.id).pipe(
        tap(() => this.campaignPublication.toxAgreement = !this.campaignPublication.toxAgreement),
        finalize(() => this.showSpinner = false),
        catchError(error => {
          this.errorManager.catchError(error);
          return observableThrowError(error);
        })
      ).subscribe();
    }
  }

  openModalRandomisation() {
    const modal = this.modalService.open(ModalRandomisationComponent, this.modalSmallOption);
    modal.componentInstance.campaignPublication = this.campaignPublication;
    modal.result.then(
      (res: CampaignPublicationPut) => {
        if (res.errorUpdateSideWithAnswers) {
          this.campaignPublication.evaluations = res.evaluations;
          this.errorManager.displayMessage('ON_SUCCESS_UPDATE_RANDO_WITH_ERRORS', 'warning');
        } else {
          this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
        }
        this.campaignPublication.allSidesNotDefined = res.allSidesNotDefined;
      }
    ).catch(() => {
    });
  }

  isWorkflowsUpToDate(workflows: Workflow[]): boolean {
    for (const wk of workflows) {
      const wkMeta: Workflow = _.get(this.campaignService, 'workflows', []).find(wkMetaData => wkMetaData.id === wk.id);
      if (wkMeta && wkMeta.updated_on !== wk.updated_on) {
        return false;
      }
    }
    return true;
  }

  publish(campaignPublication: CampaignPublication) {
    if (campaignPublication.actiview && _.isEmpty(campaignPublication.actiview.studyType)) {
      this.errorManager.displayMessage('ERROR_STUDY_TYPE_SELECTION', 'danger');
    }
    if (!this.isWorkflowsUpToDate(campaignPublication.workflows)) {
      this.errorManager.displayMessage('WORKFLOWS_OUT_OF_DATE_PUBLISH', 'warning');
    }
    if (_.isEmpty(campaignPublication.workflows)) {
      this.errorManager.displayMessage('ERROR_WORKFLOW_SELECTION', 'danger');
    } else {
      // si non template si on a qu'une seule visite vide et que l'on a créer des boucles
      if (!_.isEmpty(campaignPublication.loopAndNoVisits)) {
        this._confirmPublish(campaignPublication.loopAndNoVisits, 'LOOPS_AND_NO_VISITS');
      } else if (campaignPublication.isRoutine && campaignPublication.missVisits) {
        this._confirmPublish(campaignPublication.loopAndNoVisits, 'MISS_VISITS');
      } else {
        this.publishCampaign();
      }
    }
  }

  private publishCampaign() {
    this.showSpinner = true;
    this.campaignService.publishCampaign(this.campaignPublication.id).pipe(
      catchError(err => {
        this.showSpinner = false;
        console.log("publication err", err)
        this.errorManager.displayMessage(err.error.error, 'danger');
        return of();
      }),
      tap((campaign: Campaign) => {
        this.displayRandomisation = true;
        this.campaignPublication.state = campaign.state;
        this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
        this.campaignPublication.evaluations = _.get(campaign, 'evaluations', []);
        this.hasEvaluations = !_.isEmpty(this.campaignPublication.evaluations);
        this.showSpinner = false;
      }),
      mergeMap(campaign => this.campaignService.updateUserCampaign(campaign))
    ).subscribe();
  }

  suspend() {
    this.utilService.translateMessageModal('SUSPEND_CAMPAIGN', '', '').pipe(
      mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)),
      mergeMap(() => this.suspendCampaign(this.campaignPublication))
    )
      .subscribe();
  }

  suspendCampaign(campaignPublication: CampaignPublication) {
    this.showSpinner = true;
    return this.campaignService.suspendCampaign(campaignPublication.id).pipe(
      catchError((http, err) => {
        http.error.err === 'SUSPEND_NOT_ALLOW' ? this.errorManager.displayMessage('SUSPEND_NOT_ALLOW', 'danger') : this.errorManager.catchError(http);
        return observableThrowError(http);
      }),
      tap(() => {
        this.campaignPublication.state = CampaignStates.Suspended;
        this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
        this.displayRandomisation = false;
      }),
      finalize(() => this.showSpinner = false)
    );
  }

  public abandon(campaign: Campaign): void {
    this.utilService.openCustomModal({
      title: 'CONFIRMATION',
      message: 'ABANDON_CAMPAIGN',
      customButtons: [
        {
          name: 'FIELDWORK_NOT_FINISHED',
          style: 'btn-warning',
          event: AbandonCaseEnum.FIELDWORK_NOT_FINISHED.toString()
        },
        {
          name: 'FIELDWORK_FINISHED',
          style: 'btn-danger',
          event: AbandonCaseEnum.FIELDWORK_FINISHED.toString()
        }
      ]
    }).result.then((result: string) => {
      switch (result) {
        case AbandonCaseEnum.FIELDWORK_NOT_FINISHED.toString():
          this._abandonCampaignNotFinished(campaign);
          break;
        case AbandonCaseEnum.FIELDWORK_FINISHED.toString():
          this._abandonCampaignFinished(campaign).subscribe();
          break;
        default:
          break;
      }
    }).catch();
  }

  private _abandonCampaignFinished(campaign: Campaign): Observable<CampaignPublication | Observable<never>> {
    this.showSpinner = true;
    return this.campaignService.abandonCampaign(campaign.id).pipe(
      tap(() => {
        this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
        this.campaignPublication.state = CampaignStates.Abandoned;
        this.displayRandomisation = false;
      }),
      finalize(() => this.showSpinner = false),
      catchError(error => {
        this.errorManager.catchError(error);
        return observableThrowError(error);
      }));
  }

  private _abandonCampaignNotFinished(campaign: Campaign): void {

    const body = {
      createdBy: { user: this.user.track },
      timeZone: new Date().getTimezoneOffset(),
      keepArcs: true,
      hub: this.userService.getUserHub(this.user.hub),
      workspaces: [this.user.currentWorkspace.id],
      campaignName: this.campaignPublication.name,
      metier: this.campaignPublication.metier,
      keepSynergy: true,
      onePager: new OnePager()
    };
    this.showSpinner = true;
    this.campaignService.putCampaignState(campaign.id, { state: CampaignStates.Abandoned, oldState: CampaignStates.Published }).pipe(
      mergeMap(() => this.campaignService.duplicateCampaign(campaign.id, body)),
      tap((newCampaign: {id: string}) => {
        this.showSpinner = false;
        this.router.navigate(['/campaigns', newCampaign.id, 'edit']);
      })
    ).subscribe();
  }

  private _confirmPublish(loopAndNoVisits, type: string) {
    // tslint:disable-next-line:max-line-length
    // Des boucles ont été créées mais aucune visite n'ont été paramétrées, que voulez vous faire" => Deux options: "publier quand meme" ou "éditer les visites"
    const modal = this.modalService.open(ConfirmPublishModalComponent, this.modalSmallOption);
    if (type === 'LOOPS_AND_NO_VISITS') {
      modal.componentInstance.loopAndNoVisits = loopAndNoVisits;
    }
    modal.componentInstance.type = type;
    modal.result.then(() => {
      this.publishCampaign();
    }, () => {
      this.router.navigate(['campaigns', this.idCampaign, 'edit', type === 'LOOPS_AND_NO_VISITS' ? 'visits' : 'formulas']);
    });
  }

  finish(campaignPublication: CampaignPublication) {
    this.utilService.translateMessageModal('FINISH_CAMPAIGN', '', '').pipe(
      mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)),
      mergeMap(() => this.finishCampaign(campaignPublication.id)))
      .subscribe(() => this.onSuccessUpdateState(campaignPublication, CampaignStates.Finished));
  }

  finishCampaign(id: string) {
    this.showSpinner = true;
    return this.campaignService.putCampaignState(id, { state: CampaignStates.Finished, oldState: this.campaignPublication.state }).pipe(
      tap(() => this.campaignPublication.state = CampaignStates.Finished),
      finalize(() => this.showSpinner = false),
      catchError(error => {
        this.errorManager.catchError(error);
        return observableThrowError(error);
      }));
  }

  private onSuccessUpdateState(campaignPublication: CampaignPublication, state: CampaignStates) {
    this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
    campaignPublication.state = state;
  }

  /**
   * 21360
   */
  openModalActiviewData() {
    const modalActiviewData = this.modalService.open(ActiviewDataComponent, this.modalOption);
    modalActiviewData.componentInstance.campaignId = this.campaignPublication.id;
    modalActiviewData.componentInstance.isVolunteer = _.get(this.campaignPublication, 'users.isVolunteer', false);
    modalActiviewData.result.then(() => {
      this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
    });
  }


  chooseCaracterisation() {
    const modalOption: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    };
    const options = _.cloneDeep(modalOption);
    const modal = this.modalService.open(AddCharacterizationComponent, options);
    const characFromCampaign = _.get(this.campaignPublication, 'characterizations', []);
    modal.componentInstance.setDefaultValues = false;
    modal.componentInstance.characterizations = !_.isEmpty(characFromCampaign)
      ? _.cloneDeep(characFromCampaign)
      : _.cloneDeep(_.get(this.user, 'currentWorkspace.preferences.characterizations.' +
        this.campaignService.getArcsSystemFromHub(this.campaignPublication.hub), []));
    modal.componentInstance.metier = this.campaignPublication.metier;
    modal.componentInstance.hub = this.campaignService.getArcsSystemFromHub(this.campaignPublication.hub);
    modal.result.then(
      (carac: AttributeData[]) => {
        carac = carac.filter(c => c.isSelected);
        if (!_.isEqual(_.get(this.campaignPublication, 'characterizations', []), carac)) {
          this.showSpinner = true;
          this.campaignPublication.characterizations = carac;
          this.campaignService.putCampaignPublicationCharac(this.campaignPublication.id, carac).pipe(
            catchError(err => {
              this.errorManager.catchError(err);
              return observableThrowError(err);
            }),
            tap(() => {
              this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
            }),
            finalize(() => {
              this.showSpinner = false;
            })
          ).subscribe();
        }
      });
  }

  chooseConfortTime() {
    const modalOption: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    };
    const options = _.cloneDeep(modalOption);
    const modal = this.modalService.open(ConfortModalComponent, options);
    modal.componentInstance.idCampaign = this.campaignPublication.id;
    modal.result.then(
      () => {});
  }

  public checkPublishStatus(): boolean {
    return this.campaignPublication.hasSynergy && !this.campaignPublication.toxAgreement
      && !this.campaignPublication.isTemplate;
  }

}
